
@import "../_base.scss";

.auth-layout {
    background-color: $background-color;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    
    .promo-container,
    .content-container {
        flex: 1;
    }

    .promo-container {
        display: none;

        img {
            max-width: 80%;
            display: block;
            margin: auto;
        }
    }

    .content-container {
        background-color: $primary-color;
        overflow-y: auto;

        img.logo-center {
            display: block;
            margin: 2rem auto;
            height: 150px;
        }
    }

    .form-container {
        padding: 1.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 2rem;

        .form-outline {
            label {
                margin-bottom: 1rem;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .auth-layout {
        flex-wrap: nowrap;
        flex-direction: row;
        
        .promo-container {
            display: grid;
            place-items: center;
        }

        .content-container {
            img.logo-center {
                display: none;
            }
        }

        .form-container {
            padding: 4rem;
        }
    }
}