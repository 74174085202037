
@import "../_base.scss";

.not-found-container {
    width: 100vw;
    height: 100vh;
    display: grid;
    align-items: center;
    text-align: center;

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10vh;

        img {
            width: min(100%, 400px);
            padding-inline: 1rem;
        }

        a {
            background-color: $primary-color;
            padding-inline: 4rem;
            color: white;
        }
    }
}