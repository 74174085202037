
@import "../_base.scss";

.config-container {
    .config-list-container {
        border-radius: 1rem;
        border: 1px solid #ddd;
        
        .item {
            display: block;
            border-bottom: 1px solid #ddd;
            padding: 1rem;
            cursor: pointer;

            &:hover {
                color: unset;
                background-color: $white-color;
            }

            &:first-child {
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
            }

            &:last-child {
                border-bottom: 0;
                border-bottom-right-radius: 1rem;
                border-bottom-left-radius: 1rem;
            }
        }
    }
    
    .config-panel-container {
        margin-top: 2rem;
        border-radius: 1rem;
        border: 1px solid #ddd;
        padding: 1rem;

    }
}