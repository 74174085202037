
@import "../_base.scss";

.miscellaneous-layout {
    background-color: $background-color;

    header.minimal-header {
        padding: 0.25rem 1rem;
        display: grid;
        place-items: center;
        background-color: $secondary-color;
        color: white;
        height: calc(60px + 0.25rem * 2);
        text-align: center;
        font-size: 12px;
    }
    
    header.main-header {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding-right: 2rem;
        background-color: $primary-color;
        color: white;
        height: 105px;

        .title {
            font-size: 1.6em;
            font-weight: bold;
            line-height: normal !important;
            margin-bottom: 0;
            color: white;
        }

        .menu-icon{
            font-size: 44px;
            cursor: pointer;
        }

        .menu-links {
            display: none;
        }

        a {
            margin-inline: 1rem;
        }

        .btn-auth {
            font-size: 1.05em;
            border: 3px solid white;
            color: white;
            transition: 1s;
            margin-left: 2rem;

            &:hover {
                background-color: white;
                color: $primary-color;
            }
        }

        .auth-link {
            font-size: 1.05em;
        }
    }

    .menu-links-block {
        padding: 1rem;
        background-color: $primary-color;
        user-select: none;

        a {
            text-align: center;
            display: block;
            color: white;
            padding: .75rem;
        }
    }

    .content-container {
        min-height: 50vh;
    }

    footer.main-footer {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        justify-content: space-between;
        padding: 3rem;
        background-color: $primary-color;
        border-top: 9rem solid $secondary-color;
        color: white;

        .footer-column {
            width: 100%;
        }

        .logo {
            max-width: 100%;
            display: block;
            margin: auto;
        }

        p {
            margin-block: 0.25rem;
        }

        a {
            text-decoration: underline;

            &:hover {
                color: $secondary-color;
                text-decoration: underline;
            }
        }

        .social-img {
            max-width: 35px;
        }
    }
}

@media (min-width: 576px) {
    .miscellaneous-layout {
        header.minimal-header {
            padding: 0;
            height: 25px;
        }
        
        header.main-header {
            padding: 1.5rem;

            .title {
                font-size: 1.8em;
            }

            .menu-icon{
                display: none;
            }

            .menu-links {
                display: block;
            }
        }

        .menu-links-block {
            display: none;    
        }
    }
}

@media (min-width: 992px) {
    .miscellaneous-layout {
        header.main-header {
            .title {
                font-size: 2.4em;
            }
        }

        footer.main-footer {
            flex-wrap: nowrap;
            padding: 1.8rem;
            border-top: 12rem solid $secondary-color;
        }
    }
}