
@import "../_base.scss";


.register-container {

    .checkbox-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .checkbox-container input {
        width: 20px;
        height: 20px;
        border: 2px solid #333;
        border-radius: 1rem;
        margin-top: -1.2rem;
    }

    .error-text {
        color: $error-color;
    }
    
}
