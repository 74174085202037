
@import "../_base.scss";

.friendship-component {
    width: 100%;
    margin: 2rem auto;
    padding: 1rem;
    box-shadow: 0px 5px 10px #ccc;
    border: 0;
    border-radius: 1rem;
}