
@import "../_base.scss";

.event-component {
    width: 100%;
    margin: 2rem auto;
    padding: 1rem;
    box-shadow: 0px 5px 10px #ccc;
    border: 0;
    border-radius: 1rem;
}

@media screen and (min-width: 678px) {
    .post {
        .statics-container {
            a {
                font-size: 1em;
            }
    
            .like-button {
                font-size: 22px;
            }
        }

        .create-comment-container {
            padding-left: 1rem;

            .profile-picture {
                width: min(100px, 33%);
            }

            .create-comment-textarea {
                padding: 1rem;
                margin: 2rem;
            }
        }
    }
}