
@import "../_base.scss";

.about-container {
    border-left: 6px solid $primary-color;
    
    video, img {
        display: block;
        margin: 2rem auto;
        max-height: 500px;
    }

    .progress-text {
        padding-block: 10rem;
    }
}