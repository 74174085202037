
@import "../_base.scss";

.profile-container {
    background: linear-gradient(to bottom, $primary-color 0%, #fff 10%);
    border-radius: 10rem;
    padding-top: 2rem;

    header {
        text-align: center;

        img.picture {
            width: 100%;
            max-width: 300px;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            margin-bottom: 1rem;
        }

        .title {
            font-size: 2rem;
        }

        .in-memory-text {
            font-size: 1.4rem;
            color: #777;
        }
    }

    .card-container {
        background-color: white;
        box-shadow: 0px 5px 10px #ccc;
        border-radius: 1rem;
        margin-block: 2rem;
        padding: 2rem;
        border: 0;
    }

    .info-container {
        .row {
            display: flex;
            gap: 2rem;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-block: 3rem 1rem;

            .item {
                width: calc(25% - 1rem);

                &.full {
                    width: 100%;
                }

                &.semi-full {
                    width: calc(50% - 1rem);
                }
            }
        }
    }

    .event-container {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-block: 3rem 1rem;

        .event {
            width: calc(50% - 1rem);

            img {
                max-width: 100%;
            }

            .image-wrapper {
                position: relative;

                .date {
                    position: absolute;
                    background-color: white;
                    top: 10px;
                    left: 10px;
                    padding: 5px 10px;
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .profile-container {
        .info-container {
            .row {
                gap: 1rem;
            }
        }
    }
}