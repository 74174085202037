
@import "../_base.scss";

.home-container {
    .above-the-fold-container {
        position: relative;
        height: calc(100vh - 105px - (60px + 0.25rem * 2));
        background-image: url('../../images/home.jpg');
        background-size: cover;
        background-position: center;
        border-bottom: 1rem solid $secondary-color;

        .box-container {
            position: absolute;
            top: 10vh;
            right: 10%;
            width: 80%;
            height: 70%;
            background-color: white;
            border-radius: 2rem;
            padding: 6rem 3rem;
            padding-top: 0;
            text-align: center;
            transition: transform 1s;
            font-size: 14px;

            &:hover {
                transform: scale(1.025);
            }

            .subtitle {
                font-size: 1.2em;
            }

            .logo {
                max-height: 450px;
                max-width: 100%;
                transition: transform 1s;
                margin-block: 3rem;

                &:hover {
                    transform: scale(1.05);
                }
            }
            
            button {
                margin-block: 2rem;
                padding: 1rem;
                background-color: $primary-color;
                color: white;
                border: 0;
                border-radius: 1rem;
                transition: transform 1s;

                svg {
                    font-size: 1.5em;
                    margin-left: 10px;
                }

                a {
                    color: white;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }

    .block-container {
        padding: 3rem;
        margin-block: 5rem;
        color: white;

        .round {
            border-radius: 100%;
        }

        .row {
            margin-block: 3rem;
            gap: 1rem;
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            & > div {
                flex: 1;

                img {
                    display: block;
                    margin: auto;
                    width: min(100%, 200px);
                }
            }

        }

        &.supporters-container {
            background-color: #a29bfe;
        }

        &.creators-container {
            background-color: #2980b9;
        }

        &.promoters-container {
            background-color: #27ae60;
        }

        &.participations-container {
            background-color: #b71540;
        }

        &.awards-container {
            background-color: #f9ca24;
        }

    }
}

@media (min-width: 576px) {
    .home-container {
        .above-the-fold-container {
            height: calc(100vh - 105px - 25px);
        }
    }
}

@media (min-width: 768px) {
    .home-container {
        .above-the-fold-container {
            .box-container {
                position: absolute;
                top: 2vh;
                right: 10vw;
                width: 729px;
                height: 700px;
                background-color: white;
                border-radius: 2rem;
                padding: 6rem;
                padding-top: 0;
                text-align: center;
                transition: transform 1s;

                .logo {
                    margin-block: 7rem;
                }       
            }
        }
    }
}

@media (min-width: 992px) {
    .home-container {
        
    }
}