
@import "../_base.scss";

.post-component {
    width: 100%;
    margin: 2rem auto;
    padding: 1rem;
    box-shadow: 0px 5px 10px #ccc;
    border: 0;
    border-radius: 1rem;

    .header-container {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        align-items: center;

        .img-container {
            img {
                height: 60px;
                aspect-ratio: 1;
                object-fit: cover;
                border-radius: 100%;
            }
        }

        .info-container {
            width: 100%;
            overflow: auto;

            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .options-container {
            position: relative;

            &:hover .dropdown-content {
                display: block;
            }

            .options-btn {
                color: #444;
                font-size: 1.5em;
            }

            .dropdown-content {
                display: none;
                position: absolute;
                z-index: 1;
                min-width: 160px;
                box-shadow: 0px 8px 16px #ccc;
                background-color: white;
                border-radius: 1rem;
                right: 0;

                a, p {
                    display: block;
                    padding: 12px;
                    cursor: pointer;

                    &:hover {
                        background-color: #ededed;
                    }
                }
            }
        }
    }

    .content-container {
        .text {
            font-size: .9em;
            margin: 1rem 0.25rem;
        }
        
        .medias-container {
            display: flex;
            flex-wrap: wrap;
            gap: 2px;
            
            .media {
                flex: 1;

                img, video {
                    display: block;
                    margin: 0px auto;
    
                    max-width: 100%;
                    transition: transform 1s;

                    &:hover {
                        transform: scale(1.025);
                    }
                }
                
            }
        }
    }

    .statics-container {
        display: flex;
        justify-content: space-between;
        padding: 2rem 1rem;
        align-items: center;

        a {
            color: unset;
            font-size: 12px;
            cursor: pointer;
        }

        .like-button {
            font-size: 18px;
            cursor: pointer;

            &.liked {
                color: $primary-color;
            }

            &:active {
                transform: scale(1.1);
            }
        }
    }

    .create-comment-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3rem;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        .profile-picture {
            width: min(100px, 20%);
            aspect-ratio: 1;
            border-radius: 100%;
            transition: .4s transform;

            &:hover {
                transform: scale(1.1);
            }
        }

        .create-comment-textarea {
            width: 100%;
            padding: 0.75rem;
            margin: 1rem;
            resize: none;
            border-radius: 1rem;
            border: 0;
            background-color: #f5f5f5;
            outline: none;
        }

        .publish-button {
            padding: 12px;
            background-color: $primary-color;
            color: white;
            border-radius: 1rem;
            border: 0;
            transition: .4s transform;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .comment-list-container {

        .comment {
            display: flex;
            align-items: center;
            margin: 2rem;
            gap: 1rem;

            .profile-picture {
                width: min(70px, 33%);
                aspect-ratio: 1;
                border-radius: 100%;
                transition: .4s transform;
    
                &:hover {
                    transform: scale(1.1);
                }
            }
    
            .comment-content {
                padding-top: .5rem;
                line-height: 1.2em;
            }
        }
    }
}

@media screen and (min-width: 678px) {
    .post-component {
        .statics-container {
            a {
                font-size: 1em;
            }
    
            .like-button {
                font-size: 22px;
            }
        }

        .create-comment-container {
            padding-left: 1rem;

            .profile-picture {
                width: min(100px, 33%);
            }

            .create-comment-textarea {
                padding: 1rem;
                margin: 2rem;
            }
        }
    }
}