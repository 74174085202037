@import "../_base.scss";

.main-blog-page-container {
    .title {
        font-size: 2.5rem;
    }

    .subtitle {
        font-size: 1.35rem;
    }
    
    p {
        margin-block: 1rem;
    }

    img.img {
        max-width: 70vw;
        display: block;
        margin: 5rem auto;
    }

    .comment-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-bottom: 2rem;

        .img-container {
            flex-basis: 90px;
            text-align: center;

            img {
                width: 70px;
                height: 70px;
                border-radius: 100%;
            }
        }

        .comment-content {
            padding-top: 1rem;
            
            .title {
                font-size: 1em;
                font-weight: bold;
                margin: 0;
            }

            .comment {
                margin: 0;
            }
        }
    }
}