@import "_base.scss";

body {
  font-family: $primary-font;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #9e9da0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #474053;
}

a {
  color: unset;
  text-decoration: none;
  cursor: pointer;

  &.link {
    text-decoration: underline;
  }
}

p {
  margin: 0;
  padding: 0;
}

.form-control {
  border: 0;
  background-color: $gray-color;

  &.error {
    border: 1px solid rgb(var(--bs-danger-rgb));
  }

  &:focus {
    box-shadow: unset;
  }
}

.btn-primary-color {
  background-color: $primary-color;
  color: white;
}

.text-primary-color {
  color: $primary-color;
}