
@import "../_base.scss";

.notification-container {
    border-radius: 1rem;
    border: 1px solid #ddd;
    
    .item {
        position: relative;
        border-bottom: 1px solid #ddd;
        padding: 1rem;
        padding-left: 12%;
        cursor: pointer;
        line-height: 1.5em;

        &.seen::before {
            position: absolute;
            content: '';
            top: calc(50% - 10px / 2);
            left: 5%;
            border-radius: 100%;
            width: 10px;
            height: 10px;
            background-color: green;
        }

        &::before {
            position: absolute;
            content: '';
            top: calc(50% - 10px / 2);
            left: 5%;
            border-radius: 100%;
            width: 10px;
            height: 10px;
            background-color: red;
        }

        &:hover {
            background-color: #ddd;
        }

        &:last-child {
            border-bottom: 0;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }

        &:first-child {
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }
    }
}