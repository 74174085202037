
@import "../_base.scss";

.blog-layout {
    background-color: $background-color;
    
    header.main-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 1.5rem;
        background-color: $primary-color;
        color: $secondary-color;

        .title {
            font-size: 1.8em;
            font-weight: bold;
            line-height: normal !important;
            margin-bottom: 0;
        }

        a {
            margin-inline: 1rem;
        }
    }

    .content-container {
        padding: 4rem 3rem;
        min-height: 50vh;
    }

    footer.main-footer{
        display: flex;
        justify-content: space-between;
        padding: 3rem;
        background-color: $primary-color;
        border-top: 5rem solid $secondary-color;
        color: $secondary-color;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media (min-width: 576px) {
    .blog-layout {
        header.main-header {
            flex-direction: row;
            gap: 0;
        }
    }
}