@import "../_base.scss";

.loading-box-container {
    text-align: center;

    button {
        background-color: $secondary-color;
        color: white;
        border: 0;
            
        &:hover {
            background-color: $tertiary-color;
        }
    }
    
    .stage-counter-container {
        text-align: center;
        
        .dot {
            display: inline-block;
            margin: 3px;
            cursor: pointer;
            
            &.active {
                font-size: 1.5em;
            }
        }
    }
}