
@import "../_base.scss";


.chat-container {

    .contact-left-container {
        padding: 2rem;
        margin: 2rem;
        background-color: $background-color;
        border-radius: 1rem;
        flex: 1;

        .header-container {
            .title {
                font-size: 40px;
            }
        }

        .contact-list-container {
            max-height: 75vh;
            overflow-y: auto;
            padding-inline: 1rem;
            user-select: none;

            .contact-item {
                margin-block: 0.25rem;
                padding: 1rem;
                border-radius: 1rem;
                cursor: pointer;
                display: flex;
                gap: 10px;

                .contact-image {
                    border-radius: 1rem;
                    display: block;
                    margin: auto;
                    max-height: 100px;
                }
    
                .contact-name {
                    font-size: 1.6em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .last-msg-time {
                    float: right;
                    font-size: 0.9em;
                }
    
                .last-msg {
                    font-size: 1em;
                    color: lightgray;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .unread-messages-count {
                    display: grid;
                    place-items: center;
                    background-color: $primary-color;
                    color: white;
                    padding: 0;
                    width: 30px;
                    height: 30px;
                    margin-left: auto;
                    border-radius: 100%;
                }

                &:hover, &.active {
                    background-color: $primary-color;
                    color: white;
                }
            }

        }
    }

    .chat-display-container {
        padding: 4rem;
        flex: 5;

        .header-chat-display-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            width: 100%;
            border-bottom: 3px solid #eee;

            .contact-image {
                border-radius: 1rem;
                max-height: 100px;
            }

            .contact-name {
                width: 100%;
                padding-inline: 2rem;
                font-size: 1.6em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            button {
                padding-inline: 2rem;
                background-color: #a00;
                color: white;
            }
        }

        .chat-display {
            padding: 1rem;
            height: 50vh;
            overflow: auto;

            .chat-dialog {
                padding: 1rem;
                margin: 1rem;
                color: white;
                border-radius: 1rem;
                max-width: 70%;

                &.me {
                    background-color: $primary-color;
                    text-align: end;
                    margin-left: auto;
                }

                &.to {
                    background-color: $primary-color;
                }
            }
        }

        .chat-footer {
            margin-block: 1rem;

            textarea {
                padding: 1rem;
                width: 100%;
                max-height: 100%;
                max-height: 15vh;
                outline: none;
                resize: none;
                border: 2px solid #eee;
                background-color: $white-color;
                border-radius: 1rem;
            }

            button {
                display: block;
                margin-left: auto;
                border: 2px solid #ddd;
                color: #444;
                background-color: $white-color;
                padding: 1rem;
                border-radius: 1rem;
            }
        }

    }

    .empty-chat-container {
        flex: 5;
        height: 100vh;
        display: grid;
        place-items: center;
        text-align: center;

        .empty-chat-items-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 30px;
        }

        .btn {
            background-color: $primary-color;
            color: white;
        }
    }
}

.loading-chat-container {
    display: grid;
    place-items: center;
    height: 100vh;

    img {
        max-width: 70%;
        display: block;
        margin: auto;
    }

    .btn {
        display: block;
        background-color: $primary-color;
        color: white;
        margin: auto;
        max-width: 70%;
    }
}

@media screen and (min-width: 768px) {
    .chat-container {
        display: flex;
        align-items: flex-start;

        .contact-left-container {
        
        }

        .chat-display-container {

        }

        .empty-chat-container {

        }
    }
}

@media screen and (min-width: 970px) {
    .loading-chat-container {
        .btn {
            max-width: 100%;
        }
    }
}
