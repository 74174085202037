@import "../_base.scss";

.main-blog-container {
    display: flex;
    flex-direction: column;

    .content {
        flex: 4;
        padding: 0.5rem;

        .blog-post-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block: 2rem;
            padding: 2rem;
            cursor: pointer;
            background-color: $background-color;
            border-radius: 1rem;

            .blog-post-content {
                flex: 4;

                .title {
                    font-size: 1.75rem;
                }

                .subtitle {
                    font-size: 1.25rem;
                }

                .description {
                    font-size: 1rem;
                }
            }

            .blog-post-image {
                flex: 1;

                img {
                    width: 100%;
                }
            }
        }
    }

    aside {
        flex: 1;
        padding: 0.5rem;

        .ad-container {
            border: 1px solid #ccc;
            display: grid;
            place-items: center;
            height: 100px;
            margin-bottom: 1rem;
        }
    }
}

.blog-search-input {
    width: 100%;
    outline: none;
    padding: 1rem;
}

@media (min-width: 576px) {
    .main-blog-container {
        flex-direction: row;        
    }
}