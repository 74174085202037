
@import "../_base.scss";

.feed-container {
    .header-app-container {
        display: flex;
        gap: 30px;

        .img-container img {
            max-width: 80px;
            border-radius: 100%;
        }

        .create-post-container {
            flex: 1;
            
            textarea {
                width: 100%;
                height: 10vh;
                outline: none;
                resize: none;
                border: 0;
                padding-top: 1rem;
            }

            .options-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                color: $secondary-color;

                .option {
                    cursor: pointer;
                }
            }

            button {
                background-color: $primary-color;
                color: white;
            }
        }

        .img-upload-container {
            margin-block: 1rem;

            .img-box {
                display: inline-block;
                position: relative;
                cursor: pointer;
                
                .close {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }

                img {
                    width: 150px;
                    border-radius: 2rem;
                    transition: .3s;
                    
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }

    .loading-container {
        img {
            display: block;
            margin: 3rem auto;
            width: min(80%, 200px);
        }

        .title {
            text-align: center;
            margin: 3rem auto;
        }
    }
}