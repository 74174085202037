
@import "../_base.scss";

.main-layout {
    background-color: $background-color;

    .sm-logo {
        display: block;
        height: 70px;
        margin: 1rem auto 0;
    }

    header.main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        .logo-container {
            display: none;
            width: 20%;

            img {
                max-height: 50px;
            }
        }

        .nav-container {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 2rem;
        }

        .search-container {
            display: none;
            width: 100%;
            padding-inline: 1rem;
            
            .icon-container {
                background-color: #eee;
                width: 100%;
                border-radius: 1rem;
                display: flex;
                gap: 30px;
                padding-inline: 2rem;
                justify-content: center;
                align-items: center;

                input {
                    outline: none;
                    border: 0;
                    width: 100%;
                    padding: 1rem;
                    padding-left: 0px;
                    background-color: #eee;
                }
    
                .input-icon {
                    padding-bottom: 25px;
                    width: 20px;
                    height: 20px;
                }
            }

        }

        .auth-container {
            width: 100%;
            text-align: right;

            .notification-icon {
                margin-inline: 1rem;
                font-size: 1.2rem;
                cursor: pointer;
                color: $black-color;

                &.notificated::after {
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    transform: translateY(-250%);
                    background-color: red;
                    margin-right: 5px;
                }
            }

            .contact-image {
                width: 60px;
                border-radius: 100%;
                cursor: pointer;
                transition: .25s;

                &:hover {
                    transform: scale(1.15);
                }
            }
        }
    }

    .content {
        max-width: 820px;
        display: block;
        margin: 0 auto;
        padding: 1rem;
    }
}

@media (min-width: 576px) {
    .main-layout {
        .sm-logo {
            display: none;
        }

        header.main-header {
            .logo-container {
                display: block;
            }

            .nav-container {
                width: 30%;
            }

            .search-container {
            }

            .auth-container {
                width: min(100%, 260px);
            }
        }
    }
}

@media (min-width: 768px) {
    .main-layout {
        header.main-header {
            .logo-container {
            }

            .nav-container {
                justify-content: flex-start;
            }

            .search-container {
                display: block;
            }

            .auth-container {
            }
        }
    }
}

@media (min-width: 992px) {
    .main-layout {
        header.main-header {
            .logo-container {
            }

            .nav-container {
            }

            .search-container {
            }

            .auth-container {
                width: 20%;
            }
        }
    }
}